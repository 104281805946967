const scratchProjectsData = [
    {
        icon: "./scratchImg/ping-pong.png",
        title: "ping pong",
        src: "https://scratch.mit.edu/projects/685657268/embed",
    },

    {
        icon: "./scratchImg/Snake-game.png",
        title: "snake game",
        src: "https://scratch.mit.edu/projects/663714930/embed",
    },

    {
        icon: "./scratchImg/az-tanks.png",
        title: "AZ game",
        src: "https://scratch.mit.edu/projects/781826376/embed",
    },

    {
        icon: "./scratchImg/Rescue-pen.png",
        title: "Rescue-pen",
        src: "https://scratch.mit.edu/projects/795384239/embed",
    },

    {
        icon: "./scratchImg/suvrival.png",
        title: "suvrival",
        src: "https://scratch.mit.edu/projects/852529771/embed",
    },

    {
        icon: "./scratchImg/tetris.png",
        title: "tetris",
        src: "https://scratch.mit.edu/projects/685215621/embed",
    },
    {
        icon: "./scratchImg/ZOMBIE-GAME.png",
        title: "Zombe game",
        src: "https://scratch.mit.edu/projects/699035496/embed",
    },
    {
        icon: "./scratchImg/Donkey-kong.png",
        title: "Donkey Kong",
        src: "https://scratch.mit.edu/projects/717382144/embed",
    },
    {
        icon: "./scratchImg/mini-games.png",
        title: "friv",
        src: "https://scratch.mit.edu/projects/752884360/embed",
    },
    {
        icon: "./scratchImg/Millionaire.png",
        title: "Millionaire",
        src: "https://scratch.mit.edu/projects/781374878/embed",
    },

    {
        icon: "./scratchImg/flappy-bird.png",
        title: "flappy bird",
        src: "https://scratch.mit.edu/projects/873331546/embed",
    },

    {
        icon: "./scratchImg/x&o.png",
        title: "x&o",
        src: "https://scratch.mit.edu/projects/874613739/embed",
    },


    {
        icon: "./scratchImg/flappybird2.png",
        title: "Flappy Bird",
        src: "https://scratch.mit.edu/projects/918733327/embed",
    },

    {
        icon: "./scratchImg/CarDriveing.png",
        title: "Car Driveing",
        src: "https://scratch.mit.edu/projects/920468858/embed",
    },

    {
        icon: "./scratchImg/haneluk.png",
        title: "hanelukner",
        src: "https://scratch.mit.edu/projects/930229262/embed",
    },
    {
        icon: "./scratchImg/12locks.png",
        title: "12locks",
        src: "https://scratch.mit.edu/projects/959060481/embed",
    },
    {
        icon: "./scratchImg/MergeFellas.png",
        title: "Merge fllas",
        src: "https://scratch.mit.edu/projects/959635884/embed",
    },
    {
        icon: "./scratchImg/Aladdin.png",
        title: "Aladdin",
        src: "https://scratch.mit.edu/projects/946988320/embed",
    },
    {
        icon: "./scratchImg/Tank.png",
        title: "Tank",
        src: "https://scratch.mit.edu/projects/958645248/embed",
    },
    {
        icon: "./scratchImg/CarParking.png",
        title: "Car Parking",
        src: "https://scratch.mit.edu/projects/957946481/embed",
    },
    {
        icon: "./scratchImg/Ferma.png",
        title: "Ferma",
        src: "https://scratch.mit.edu/projects/944726753/embed",
    },
    {
        icon: "./scratchImg/Beauty_and_the_Knight.png",
        title: "Beauty and the Knight",
        src: "https://scratch.mit.edu/projects/918191902/embed",
    },
    {
        icon: "./scratchImg/stormshout.png",
        title: "Stormshout",
        src: "https://scratch.mit.edu/projects/997786324/embed",
    },
    {
        icon: "./scratchImg/Flappybird.png",
        title: "Flappy bird",
        src: "https://scratch.mit.edu/projects/1002018863/embed",
    },
    {
        icon: "./scratchImg/Games_with_Stages.png",
        title: "Games with Stages",
        src: "https://scratch.mit.edu/projects/991431685/embed",
    },
    {
        icon: "./scratchImg/the_car_game.png",
        title: "The car game",
        src: "https://scratch.mit.edu/projects/993519540/embed",
    },
    {
        icon: "./scratchImg/thecargame2.png",
        title: "The car game 2",
        src: "https://scratch.mit.edu/projects/661388241/embed",
    },
    {
        icon: "./scratchImg/ECO_game.png",
        title: "ECO game",
        src: "https://scratch.mit.edu/projects/1009464470/embed",
    },
    {
        icon: "./scratchImg/Geometry_dash.png",
        title: "Geometry dash",
        src: "https://scratch.mit.edu/projects/992162937/embed",
    },
    {
        icon: "./scratchImg/Cowgame.png",
        title: "Cow game",
        src: "https://scratch.mit.edu/projects/640767204/embed",
    },
    {
        icon: "./scratchImg/fruitNinja.png",
        title: "Fruit Ninja",
        src: "https://scratch.mit.edu/projects/675557392/embed",
    },
    {
        icon: "./scratchImg/make-up.png",
        title: "Make Up Girl",
        src: "https://scratch.mit.edu/projects/676845019/embed",
    },
    {
        icon: "./scratchImg/SAO.png",
        title: "SAO RPG",
        src: "https://scratch.mit.edu/projects/682944905/embed",
    },
    {
        icon: "./scratchImg/Naruto.png",
        title: "Naruto VS Sasuke",
        src: "https://scratch.mit.edu/projects/718316630/embed",
    },
    {
        icon: "./scratchImg/rider.png",
        title: "Rider",
        src: "https://scratch.mit.edu/projects/1044385363/embed",
    },
    {
        icon: "./scratchImg/planet.png",
        title: "Planets Game",
        src: "https://scratch.mit.edu/projects/1043879151/embed",
    },
    {
        icon: "./scratchImg/stickman.png",
        title: "Stickman Party",
        src: "https://scratch.mit.edu/projects/1051037621/embed",
    },
    {
        icon: "./scratchImg/intelect.png",
        title: "Logical Questions",
        src: "https://scratch.mit.edu/projects/1051959653/embed",
    },
    {
        icon: "./scratchImg/mortalcombat.png",
        title: "Mortal Combat",
        src: "https://scratch.mit.edu/projects/1052668550/embed",
    },
    {
        icon: "./scratchImg/Friv26.png",
        title: "Friv",
        src: "https://scratch.mit.edu/projects/1074074471/embed",
    },
    {
        icon: "./scratchImg/maze.png",
        title: "Maze Game",
        src: "https://scratch.mit.edu/projects/680242729/embed",
    },
];

export default scratchProjectsData;

// const scratchProjectsData = [
//     {    
//         icon: "./scratchImg/ping-pong.png",
//         title: "ping pong",
//         src: "https://scratch.mit.edu/projects/685657268/embed",
//     },

//     {
//         icon: "./scratchImg/Snake-game.png",
//         title: "snake game",
//         src: "https://scratch.mit.edu/projects/663714930/embed",
//     },

//     {
//         icon: "./scratchImg/az-tanks.png",
//         title: "AZ game",
//         src: "https://scratch.mit.edu/projects/781826376/embed",
//     },

//     {
//         icon: "./scratchImg/pen.png",
//         title: "pen",
//         src: "https://scratch.mit.edu/projects/795384239/embed",
//     },

//     {
//         icon: "./scratchImg/suvrival.png",
//         title: "suvrival",
//         src: "https://scratch.mit.edu/projects/852529771/embed",
//     },

//     {
//         icon: "./scratchImg/tetris.png",
//         title: "tetris",
//         src: "https://scratch.mit.edu/projects/685215621/embed",
//     },
//     {
//         icon: "./scratchImg/suvrival.png",
//         title: "Zombe game",
//         src: "https://scratch.mit.edu/projects/699035496/embed",
//     },
//     {
//         icon: "./scratchImg/Donkey-kong.png",
//         title: "Donkey Kong",
//         src: "https://scratch.mit.edu/projects/717382144/embed",
//     },
//     {
//         icon: "./scratchImg/mini-games.png",
//         title: "friv",
//         src: "https://scratch.mit.edu/projects/752884360/embed",
//     },
//     {
//         icon: "./scratchImg/Millionaire.png",
//         title: "Millionaire",
//         src: "https://scratch.mit.edu/projects/781374878/embed",
//     },

//     {
//         icon: "./scratchImg/flappy-bird.png",
//         title: "flappy bird",
//         src: "https://scratch.mit.edu/projects/873331546/embed",
//     },

//     {
//         icon: "./scratchImg/x&o.png",
//         title: "x&o",
//         src: "https://scratch.mit.edu/projects/874613739/embed",
//     },

//     {
//         icon: "./scratchImg/Aram.png",
//         title: "Aram",
//         src: "https://scratch.mit.edu/projects/921874567/embed",
//     },

//     {
//         icon: "./scratchImg/BeautyandtheKnight.png",
//         title: "Flappy Bird",
//         src: "https://scratch.mit.edu/projects/918733327/embed",
//     },

//     {
//         icon: "./scratchImg/car-driving.png",
//         title: "Car Driveing",
//         src: "https://scratch.mit.edu/projects/920468858/embed",
//     },

//     {
//         icon: "./scratchImg/haneluk.png",
//         title: "hanelukner",
//         src: "https://scratch.mit.edu/projects/930229262/embed",
//     },

//     // {
//     //     icon: "./scratchImg/haneluk.png",
//     //     title: "hanelukner",
//     //     src: "https://scratch.mit.edu/projects/930229262/embed",
//     // },

//     // {
//     //     icon: "./scratchImg/x&o.png",
//     //     title: "the car game",
//     //     src: "https://scratch.mit.edu/projects/661388241/embed",
//     // },
// ];

// export default scratchProjectsData;
