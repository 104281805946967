import React, { useState } from "react";
import "./HomeCoursesDescription.css";
import { IoIosArrowDown } from "react-icons/io";
const HomeCoursesDescription = ({ courses }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const changePopup = () => {
    setOpenPopup((prev) => !prev);
  };
  let clas;
  openPopup === true ? (clas = "arrow arrow_rotate") : (clas = "arrow");
  return (
    <div className="home-courses-main">
      <div className="home-courses-card" onClick={changePopup}>
        <h4 className="home-courses-card-title">{courses.title}</h4>

        <IoIosArrowDown className={clas} />
        {/* {openPopup ? (
          <IoIosArrowDown className="arrow_rotate" />
        ) : (
          <IoIosArrowDown />
        )} */}
      </div>
      {openPopup && (
        <p className="home-courses-description">{courses.description}</p>
      )}
    </div>
  );
};

export default HomeCoursesDescription;
