import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSwitcher.css";
import { FaAngleDown } from "react-icons/fa";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    return (
      <div className="custom-select">
        {/* <select
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
          }}
          value={i18n.language}
        >
          <option value={"en"}>en</option>
          <option className="language_option" value={"am"}>
            am
          </option>
        </select> */}
        <div className="dropdown">
          <button className="link_botton">
            {i18n.language == "am" ? "Հայ" : "Eng"}
            <FaAngleDown className="arrow_down" />
          </button>
          <div className="dropdown-content">
            <a
              value={i18n.language}
              onClick={(e) => {
                i18n.changeLanguage("am");
              }}
            >
              Հայ
            </a>
            <a
              value={i18n.language}
              onClick={(e) => {
                i18n.changeLanguage("en");
              }}
            >
              Eng
            </a>
          </div>
        </div>
      </div>
    );
};

export default LanguageSwitcher;
