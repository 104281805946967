import React from "react";
import {  NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaAngleDown } from "react-icons/fa";
import "./Navigation.css";

const Navigation = ({
    scrollCoursesComponent,
    scrollCenterComponent,
    scrollTeatcherComponent,
    scrollAboutComponent,
    scrollContactComponent,
}) => {
    const { t } = useTranslation();
    return (
      <nav className="navigation">
        <div className="dropdown">
          <NavLink
            className="link_botton"
            to="./courses"
            onClick={scrollCoursesComponent}
          >
            {t("Navigation.Courses")}

            <FaAngleDown className="arrow_down" />
          </NavLink>
          <div className="dropdown-content">
            <NavLink to="./sine_up">
              {t("Presentation.PresentationBTN")}
            </NavLink>
            {/* <NavLink to="./webProjects">{t("Navigation.Our_sites")}</NavLink>
            <NavLink to="./products" onClick={scrollContactComponent}>
              {t("Navigation.products")}
            </NavLink> */}
          </div>
        </div>
        {/* <NavLink to="/" onClick={scrollCenterComponent}>
                {t("Navigation.AboutUs")}
            </NavLink>
            <NavLink to="/" onClick={scrollTeatcherComponent}>
                {t("Navigation.Teachers")}
            </NavLink> */}
        {/* <NavLink to="/" onClick={scrollAboutComponent}>
                {t("Navigation.Advantages")}
            </NavLink> */}
        {/* <NavLink to="/" onClick={scrollContactComponent}>
                {t("Navigation.Contact")}
            </NavLink> */}
        {/* <NavLink to="./sine_up" onClick={scrollContactComponent}>
          {t("Navigation.sign_up")}
        </NavLink> */}

        {/* <Link to={"./projects"}>{t("Navigation.Projects")}</Link> */}
        <div className="dropdown">
          <button className="link_botton">
            {t("Navigation.Projects")}
            <FaAngleDown className="arrow_down" />
          </button>
          <div className="dropdown-content">
            <NavLink to="./projects">{t("Navigation.Our_games")}</NavLink>
            <NavLink to="./webProjects">{t("Navigation.Our_sites")}</NavLink>
            <NavLink to="./products" onClick={scrollContactComponent}>
              {t("Navigation.products")}
            </NavLink>
          </div>
        </div>

        <NavLink to="./odersite" onClick={scrollContactComponent}>
          {t("Navigation.Order_website")}
        </NavLink>
      </nav>
    );
};

export default Navigation;
