import React, { forwardRef } from "react";
import "./HomeCenter.css";
import center from "../../../assets/img/Image.png";
import { useTranslation } from "react-i18next";
import Data from "./component/data";

const HomeCenter = ({}, ref) => {
    const { t } = useTranslation();
    return (
      <div className="home-center" ref={ref}>
        <h1 className="home-center-title">{t("Center.bodyTitle")}</h1>
        {/* <p className="home-center-subtitle">{t("Center.subscribe")}</p> */}
        <div className="home-center-block">
          <div className="home-center-block-left">
            <img src={"/arrayOfImg/newHomePic.jpg"} alt="about_img" />
          </div>
          <div className="home-center-block-right">
            <Data />
          </div>
        </div>
      </div>
    );
};

export default forwardRef(HomeCenter);
