import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./HomeContact.css";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../message/ErrorMessage";

const HomeContact = ({}, ref) => {
    const [message, setMessage] = useState({
        text: "",
        type: false,
    });
    const { t } = useTranslation();
    const form = useRef();
    const onchange = () => {
        setMessage({
            text: "",
            type: false,
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm("service_or6cmr3", "template_nw3jtfz", form.current, "Oj3Yiwfd0qXpS62Dl")
            .then(
                (result) => {
                    // console.log(result.text);
                    // console.log("message send");
                    setMessage({
                        text: result.text,
                        type: true,
                    });
                },
                (error) => {
                    // console.log(error.text);
                    setMessage({
                        text: error,
                        type: false,
                    });
                }
            );
    };

    return (
        <div className="home-contact" ref={ref}>
            <h1 className="home-contact-title">{t("Feedback")}</h1>
            <p className="home-contact-subtitle">{t("Feedback_desc")}</p>
            <div className="home-contact-block">
                <div className="home-contact-block-left">
                    <img src={"/arrayOfImg/homeContact.png"} className="app_image" alt="contact"/>
                </div>
                <div className="home-contact-block-right">
                    <form ref={form} onSubmit={sendEmail} className="contacts-form">
                        <label>{t("Name")}</label>
                        <input type="text" className="inputs" name="user_name" onChange={onchange} />
                        <label>{t("Email")}</label>
                        <input type="email" className="inputs" name="user_email" onChange={onchange} />
                        <label>{t("Message")}</label>
                        <textarea name="message" onChange={onchange} />
                        <ErrorMessage message={message} />
                        <input type="submit" value={t("send")} className="contact-btn" />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(HomeContact);
