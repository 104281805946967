import React from "react";
import "./HomeAboutDescription.css";
import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";

const HomeAboutDescription = ({ about }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const changePopup = () => setOpenPopup((prev) => !prev);
  let clas;
  openPopup === true ? (clas = "arrow arrow_rotate") : (clas = "arrow");
  return (
    <div className="home-about-description-ac-block">
      <div className="home-about-description-ac" onClick={changePopup}>
        <h4 className="home-courses-card-title">{about.title}</h4>
        <IoIosArrowDown className={clas} />
      </div>
      {openPopup && <p className="home-about-description-text">{about.body}</p>}
    </div>
  );
};

export default HomeAboutDescription;
