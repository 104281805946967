import React, { useState, useRef } from "react";
import GlobalFunctions from "./GlobalFunctions/GlobalFunctions";
import "./Header.css";
import Navigation from "./Navigation/Navigation";
// import logo from ";
import { NavLink } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";

const Header = ({
    // scrollCoursesComponent,
    scrollCenterComponent,
    scrollTeatcherComponent,
    scrollAboutComponent,
    scrollContactComponent,
}) => {
    const [openMenu, setOpenMenu] = useState(false);

    const useHeaderRef = useRef(null);

    const showMenu = () => {
        if (document.body.offsetWidth < 800) {
            setOpenMenu(!openMenu);
            if (useHeaderRef.current.style.display === "flex") {
                useHeaderRef.current.style.display = "none";
            } else {
                useHeaderRef.current.style.display = "flex";
            }
        }
    };

    return (
      <div className="header_container">
        <div className="header-logo-moble">
          <NavLink to="/">
            <img className="logo_img" src={"/logo.png"} />
          </NavLink>
        </div>
        <div className={`burger_menu `} onClick={showMenu}>
          {openMenu ? (
            <AiOutlineClose className="menu_button" />
          ) : (
            <RxHamburgerMenu className="menu_button" />
          )}
        </div>

        <header className="header" ref={useHeaderRef}>
          <div className="headerInner">
            <div className="header-logo">
              <NavLink to="/">
                <img className="logo_img" src={"/logo.png"} />
              </NavLink>
            </div>
            <div className="header_left_block">
              <Navigation
                // scrollCoursesComponent={scrollCoursesComponent}
                scrollCenterComponent={scrollCenterComponent}
                scrollTeatcherComponent={scrollTeatcherComponent}
                scrollAboutComponent={scrollAboutComponent}
                scrollContactComponent={scrollContactComponent}
              />
              <GlobalFunctions />
            </div>
          </div>
        </header>
      </div>
    );
};

export default Header;
