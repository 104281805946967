import React, { forwardRef } from "react";
import "./HomeAbout.css";
import homeAbout from "../../../assets/img/about.svg";
import HomeAboutDescription from "./HomeAboutDescription/HomeAboutDescription";
import { useTranslation } from "react-i18next";

const HomeAbout = ({}, ref) => {
    const { t } = useTranslation();

    const aboutData = [
        {
            id: 1,
            title: t("Modern_methods.title"),
            body: t("Modern_methods.body"),
        },
        {
            id: 2,
            title: t("Adaptive_time_schedule.title"),
            body: t("Adaptive_time_schedule.body"),
        },
        {
            id: 3,
            title: t("No_restrictions.title"),
            body: t("No_restrictions.body"),
        },
    ];

    return (
        <div className="home-about" ref={ref}>
            <h1 className="home-about-title">{t("Why Study With Us")}</h1>
            {/* <p className="home-about-subtitle">{t("A few advantages about us")}</p> */}
            <div className="home-about-description">
                <div className="home-about-description-left">
                    {aboutData.map((about) => (
                        <HomeAboutDescription key={about.id} about={about} />
                    ))}
                </div>
                <div className="home-about-description-right">
                    <img src={"/arrayOfImg/homeAbout.png"} />
                </div>
            </div>
        </div>
    );
};

export default forwardRef(HomeAbout);
