import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeTeatcherSlider.css";
import Slider from "react-slick";

const HomeTeatcherSlider = () => {
  const { t } = useTranslation();
  const useSlideRef = useRef(null);
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const teatcherData = [
    {
      id: 1,
      name: t("teatcherData.Srine.name"),
      position: t("teatcherData.Srine.position"),
      imgUrl: "./team/Serine.jpeg",
    },
    {
      id: 2,
      name: t("teatcherData.Gevorg.name"),
      position: t("teatcherData.Gevorg.position"),
      imgUrl: "./team/Gevorg1.jpg",
    },
    {
      id: 8,
      name: t("teatcherData.Rob.name"),
      position: t("teatcherData.Rob.position"),
      imgUrl: "./team/rob.jpg",
    },

    {
      id: 3,
      name: t("teatcherData.Vahe.name"),
      position: t("teatcherData.Vahe.position"),
      imgUrl: "./team/Vahe.jpg",
    },
    // {
    //     id: 4,
    //     name: t("teatcherData.Katarina.name"),
    //     position: t("teatcherData.Katarina.position"),
    //     imgUrl: "./team/Katarina1.jpg",
    // },
    {
      id: 6,
      name: t("teatcherData.Laura.name"),
      position: t("teatcherData.Laura.position"),
      imgUrl: "./team/Laura.jpg",
    },
    // {
    //     id: 5,
    //     name: t("teatcherData.Araqsya.name"),
    //     position: t("teatcherData.Araqsya.position"),
    //     imgUrl: "./team/araqsya.jpg",
    // },

    {
      id: 7,
      name: t("teatcherData.Davit.name"),
      position: t("teatcherData.Davit.position"),
      imgUrl: "./team/davit.jpg",
    },
  ];
  const slideList = teatcherData.map((teatcher) => (
    <div className="slider-block" key={teatcher.id}>
      <div className="standartWide">
        <img src={teatcher.imgUrl} id={teatcher.name} alt={teatcher.name} />
      </div>

      <div className="teatcher-description">
        <h3>{teatcher.name}</h3>
        <p>{teatcher.position}</p>
      </div>
    </div>
  ));
  return (
    <div className="slider-container">
      {slideList?.length > 0 && (
        <Slider ref={useSlideRef} {...settings}>
          {slideList}
        </Slider>
      )}
    </div>
  );
};

export default HomeTeatcherSlider;
