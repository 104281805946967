import React from 'react';
import { useTranslation } from "react-i18next";


const Data = () => {
  const { t } = useTranslation();
  let obj = [
    { text: t("Center.body.1") },
    { text: t("Center.body.2") },
    { text: t("Center.body.3") },
    { text: t("Center.body.4") },
    { text: t("Center.body.5") },
  ];
  const p=obj.map((el,i)=>{
    return (
    <p key={i}>{el.text}</p>)
  })
  
  return (
    <div>
      {p}
    </div>
  )
}

export default Data